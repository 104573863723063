document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('exhibitions-index')) {
    const tabs = Array.from(document.querySelectorAll('[role="tab"]')).map((tab) => {
      return {
        button: tab,
        panel: document.querySelector(`[aria-labelledby="${tab.id}"]`)
      }
    })

    const clickEvent = (tab) => {
      deactiveTabs(tab)
      tab.button.setAttribute('aria-selected', 'true')
      tab.button.classList.add('active')
      tab.panel.setAttribute('aria-hidden', 'false')
      tab.panel.style.display = 'block'
    }

    const deactiveTabs = (activeTab) => {
      tabs.forEach((tab) => {
        if (tab != activeTab) {
          tab.button.setAttribute('aria-selected', 'false')
          tab.button.classList.remove('active')
          tab.panel.setAttribute('aria-hidden', 'true')
          tab.panel.style.display = 'none'
        }
      })
    }

    tabs.forEach((tab) => {
      tab.button.addEventListener('click', () => clickEvent(tab))
    })
  }
})
