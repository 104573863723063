const handleHover = (target) => {
  target.classList.toggle('visible')
}

export default () => {
  document.addEventListener('turbolinks:load', () => {
    if (document.querySelector('.list-hover')) {
      const sources = document.querySelectorAll('.list-hover-source')

      sources.forEach(source => {
        const target = document.querySelector(`.list-hover-target-${source.dataset.targetId}`)

        if (target != null) {
          source.addEventListener('mouseover', () => handleHover(target))
          source.addEventListener('mouseout', () => handleHover(target))
        }
      })
    }
  })

  document.addEventListener('turbolinks:before-cache', () => {
    if (document.querySelector('.list-hover')) {
      document.querySelectorAll('.visible').forEach(element => {
        element.classList.remove('visible')
      })
    }
  })
}
