document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('#page-index')) {
    const keyTexts = document.querySelectorAll('.key-text')
    const keyImages = document.querySelectorAll('.key-image')

    keyTexts.forEach((text, index) => {
      if (keyImages[index].firstElementChild) {
        const image = new Image()
        image.onload = () => {
          text.style.height = keyImages[index].offsetHeight + 'px'
        }
        image.src = keyImages[index].firstElementChild.src
      }
    })
  }
})
